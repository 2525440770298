export default [
    {
        path: 'volunteers/:id?',
        name: 'viewVolunteers',
        component: () => import('@/views/Admin/Volunteer/ViewVolunteers.vue'),
        meta: {
            title: "View Volunteer",
            requiresPermission: 'admin.events.show',
            breadcrumb: [
                { name: 'Dashboard', link: '/admin/dashboard' },
                { name: 'Events', link: '/admin/events' },
                { name: 'Volunteers' },
            ]
        }
    },
]
