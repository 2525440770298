import adminDashboardRoutes from './admin/dashboard'
import adminUserRoutes from './admin/user'
import adminFacultyRoutes from './admin/faculty'
import adminDegreeProgramsRoutes from './admin/degree_program'
import adminJobRoutes from './admin/job'
import adminEventRoutes from './admin/event'
import adminSettingRoutes from './admin/setting'
import volunteeringRoutes from './admin/volunteering'

export default [
    {
        path: '/admin',
        name: 'Dashboard',
        component: () => import('@/views/Admin/AppView.vue'),
        meta: {
            title: "Dashboard ",
            requiresAuth: true,
            requiresAdmin: true,
        },
        children: [
            ...adminDashboardRoutes,
            ...adminUserRoutes,
            ...adminFacultyRoutes,
            ...adminDegreeProgramsRoutes,
            ...adminJobRoutes,
            ...adminEventRoutes,
            ...adminSettingRoutes,
            ...volunteeringRoutes,
        ],
    },
]
