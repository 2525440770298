import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        degreeprograms: [],
        degree_program: {},
        id: null,
        error: null,
    },
    mutations: {
        setDegreePrograms(state: any, degreeprograms: any) {
            state.degreeprograms = degreeprograms;
        },
        setDegreeProgram(state: any, degree_program: any) {
            state.degree_program = degree_program;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        degreeprograms(state: any) {
            return state.degreeprograms;
        },
        degree_program(state: any) {
            return state.degree_program;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        postDegreeProgram(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.degreeprograms.post(data.faculty_id, data.data).then((response: any) => {
                context.commit('setDegreeProgram', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateDegreeProgram(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.degreeprograms.put(data.id, data.form).then((response: any) => {
                context.commit('setDegreeProgram', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getDegreeProgram(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.degreeprograms.get(id).then((response: any) => {
                context.commit('setDegreeProgram', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getDegreePrograms(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.degreeprograms.index(data.faculty_id, data.query).then((response: any) => {
                context.commit('setDegreePrograms', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteDegreeProgram(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.degreeprograms.delete(id).then((response: any) => {
                context.commit('setDegreeProgram', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        importDegreeProgram(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.degreeprograms.importDegreeProgram(data).then((response: any) => {
                //context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
