import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        notifications: [],
        error: null,
    },
    mutations: {
        setNotifications(state: any, notifications: any) {
            state.notifications = notifications;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
    },
    getters: {
        notifications(state: any) {
            return state.notifications;
        },
        error(state: any) {
            return state.error;
        },
    },
    actions: {
        getNotifications(context: any) {
            context.commit('setError', null);
            return api.notification.index().then((response: any) => {
                context.commit('setNotifications', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        markAsRead(context: any, id: number) {
            context.commit('setError', null);
            return api.notification.mark(id).then((response: any) => {
                context.commit('setNotifications', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        markAllAsRead(context: any) {
            context.commit('setError', null);
            return api.notification.markAll().then((response: any) => {
                context.commit('setNotifications', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteNotification(context: any, id: number) {
            context.commit('setError', null);
            return api.notification.delete(id).then((response: any) => {
                context.commit('setNotifications', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteAllNotifications(context: any) {
            context.commit('setError', null);
            return api.notification.deleteAll().then((response: any) => {
                context.commit('setNotifications', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
    },
}
