import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        educations: JSON.parse(localStorage.getItem('educations') || "[]") || [],
        education: {},
        id: null,
        error: null,
    },
    mutations: {
        setEducations(state: any, educations: any) {
            state.educations =  JSON.parse(educations);
            localStorage.educations = educations;
        },
        setEducation(state: any, education: any) {
            state.education = education;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        educations(state: any) {
            return state.educations;
        },
        education(state: any) {
            return state.education;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        updateEducation(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.education.put(data.id, data.form).then((response: any) => {
                context.commit('setEducation', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEducation(context: any, id: number) {
            context.commit('setError', null);
            return api.alumni.education.get(id).then((response: any) => {
                context.commit('setEducation', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEducations(context: any) {
            context.commit('setError', null);
            return api.alumni.education.index().then((response: any) => {
                context.commit('setEducations', JSON.stringify(response));
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteEducation(context: any, id: number) {
            context.commit('setError', null);
            return api.alumni.education.delete(id).then((response: any) => {
                context.commit('setEducation', {});
                context.commit('setEducations', JSON.stringify(response));
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
