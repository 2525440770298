export default [
    {
        path: 'events',
        name: 'adminEvents',
        component: () => import('@/views/Admin/Events/ViewEvents.vue'),
        meta: {
            title: "Events",
            requiresPermission: 'admin.events.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Events'},
            ]
        }
    },
    {
        path: 'event/manage/:id?',
        name: 'manageEvent',
        component: () => import('@/views/Admin/Events/ManageEvent.vue'),
        meta: {
            title: "Manage Event",
            requiresPermission: 'admin.events.create',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Events', link: '/admin/events'},
            { name: 'Add Event'},
            ]
        }
    },
    {
        path: 'event/:id?',
        name: 'viewEvent',
        component: () => import('@/views/Admin/Events/EventView.vue'),
        meta: {
            title: "View Event",
            requiresPermission: 'admin.events.show',
            breadcrumb: [
                { name: 'Dashboard', link: '/admin/dashboard' },
                { name: 'Events', link: '/admin/events' },
                { name: 'View Event' },
            ]
        }
    },
    {
        path: 'event/:event_id/gallery',
        name: 'addEventGallery',
        component: () => import('@/views/Admin/Events/AddGallery.vue'),
        meta: {
            title: "Add Event Gallery",
            //requiresPermission: 'admin.events.gallery',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Events', link: 'events'},
            { name: 'View Event', link: '/admin/event/:id'},
            { name: 'Add Event Gallery'},
            ]
        }
    },
]
