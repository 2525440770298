import { createStore } from 'vuex'
import Theme from './modules/Theme'
import Auth from './modules/Auth'
import User from './modules/User'
import Faculty from './modules/Faculty'
import DegreePrograms from './modules/DegreePrograms'
import Job from './modules/Job'
import Event from './modules/Event'
import Dashboard from './modules/Dashboard'
import Alumni from './modules/Alumni'
import Education from './modules/Education'
import Experience from './modules/Experience'
import Notification from './modules/Notification'
import Post from './modules/Post'
import Volunteer from './modules/Volunteer'

export default createStore({
  modules: {
    Theme,
    Auth,
    User,
    Faculty,
    DegreePrograms,
    Job,
    Event,
    Dashboard,
    Alumni,
    Education,
    Experience,
    Notification,
    Post,
    Volunteer,
  }
})
