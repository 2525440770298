export default [
    {
        path: 'setting',
        name: 'adminSettings',
        component: () => import('@/views/Admin/SettingView.vue'),
        meta: {
            title: "Settings",
            requiresPermission: 'admin.settings.index',
            breadcrumb: [
                { name: 'Dashboard', link: '/admin/dashboard' },
                { name: 'Settings' },
            ]
        }
    },
]