import { createStore } from 'vuex'
import { api } from '../../services/api'

export default {

  namespaced: true,

  state: {
    posts: [],
    post: {},
    id: null,
    error: null,
  },

  mutations: {
    setPosts (state: any, posts: any) {
      state.posts = posts;
    },
    setPost (state: any, post: any) {
      state.post = post;
    },
    setError (state: any, error: any) {
      if (error != null) {
        if (error.response.status === 422) {
          state.error = error.response.data || error.message
        }
      }
      if (error === null) {
        state.error = null
      }
    },
    setId (state: any, id: any) {
      state.id = id;
    }
  },

  getters: {
    posts (state: any) {
      return state.posts;
    },
    post (state: any) {
      return state.post;
    },
    error (state: any) {
      return state.error;
    },
    id (state: any) {
      return state.id;
    }
  },

  actions: {
    postPost (context: any, data: any) {
      context.commit('setError', null);
      return api.alumni.posts.put(data.id, data.data).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    updatePost (context: any, data: any) {
      context.commit('setError', null);
      return api.alumni.posts.put(data.id, data.form).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      }
      );
    },
    deletePost (context: any, id: any) {
      context.commit('setError', null);
      return api.alumni.posts.delete(id).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    getPost (context: any, id: any) {
      context.commit('setError', null);
      return api.alumni.posts.get(id).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    getPosts (context: any, query: string) {
      context.commit('setError', null);
      return api.alumni.posts.index(query).then((response: any) => {
        context.commit('setPosts', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    like (context: any, id: any) {
      context.commit('setError', null);
      return api.alumni.posts.like(id).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    postComment (context: any, data: any) {
      context.commit('setError', null);
      return api.alumni.posts.postComment(data.id, {id: data.comment_id, content: data.comment}).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    deleteComment (context: any, data: any) {
      context.commit('setError', null);
      return api.alumni.posts.deleteComment(data.id, data.commentId).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
    sharePost (context: any, id: number) {
      context.commit('setError', null);
      return api.alumni.posts.share(id).then((response: any) => {
        context.commit('setPost', response);
      }).catch((error: any) => {
        context.commit('setError', error);
      });
    },
  }
}
