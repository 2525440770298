import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        experiences: JSON.parse(localStorage.getItem('experiences') || "[]") || [],
        experience: {},
        id: null,
        error: null,
    },
    mutations: {
        setExperiences(state: any, experiences: any) {
            state.experiences =  JSON.parse(experiences);
            localStorage.experiences = experiences;
        },
        setExperience(state: any, experience: any) {
            state.experience = experience;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        experiences(state: any) {
            return state.experiences;
        },
        experience(state: any) {
            return state.experience;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        updateExperience(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.experience.put(data.id, data.form).then((response: any) => {
                context.commit('setExperience', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getExperience(context: any, id: number) {
            context.commit('setError', null);
            return api.alumni.experience.get(id).then((response: any) => {
                context.commit('setExperience', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getExperiences(context: any) {
            context.commit('setError', null);
            return api.alumni.experience.index().then((response: any) => {
                context.commit('setExperiences', JSON.stringify(response));
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteExperience(context: any, id: number) {
            context.commit('setError', null);
            return api.alumni.experience.delete(id).then((response: any) => {
                context.commit('setExperience', {});
                context.commit('setExperiences', JSON.stringify(response));
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
