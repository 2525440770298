export default [
    {
        path: '/profile/:id?',
        name: 'Profile',
        component: () => import('@/views/User/ProfileView.vue'),
        meta: {
            title: "Profile",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: () => import('@/views/User/SettingsView.vue'),
        meta: {
            title: "Edit Profile",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/edit-profile',
        name: 'EditProfile',
        component: () => import('@/views/User/ManageProfile.vue'),
        meta: {
            title: "Edit Profile",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/manage-education/:id?',
        name: 'ManageEducation',
        component: () => import('@/views/User/ManageEducation.vue'),
        meta: {
            title: "Manage Education",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/manage-experience/:id?',
        name: 'ManageExperience',
        component: () => import('@/views/User/ManageExperience.vue'),
        meta: {
            title: "Manage Experience",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/change-password',
        name: 'ChangePassword',
        component: () => import('@/views/User/ChangePassword.vue'),
        meta: {
            title: "Change Password",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/alumni',
        name: 'Alumni',
        component: () => import('@/views/User/AlumniView.vue'),
        meta: {
            title: "Alumni",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/events',
        name: 'Events',
        component: () => import('@/views/User/EventsView.vue'),
        meta: {
            title: "Events",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/event/:id?',
        name: 'Event View',
        component: () => import('@/views/User/EventView.vue'),
        meta: {
            title: "Event View",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/jobs',
        name: 'Jobs',
        component: () => import('@/views/User/JobsView.vue'),
        meta: {
            title: "Jobs",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/jobs/list',
        name: 'manageJobs',
        component: () => import('@/views/User/Jobs/ManageJobs.vue'),
        meta: {
            title: "Manage Job",
            requiresAuth: true,
            requiresAdmin: false,
            breadcrumb: [
                { name: 'Jobs', link: '/jobs' },
                { name: 'Manage' },
            ]
        },
    },
    {
        path: '/job/manage/:id?',
        name: 'addJob',
        component: () => import('@/views/User/Jobs/AddJob.vue'),
        meta: {
            title: "Add Job",
            requiresAuth: true,
            requiresAdmin: false,
            breadcrumb: [
                { name: 'Jobs', link: '/jobs' },
                { name: 'Manage', link: '/jobs/list' },
                { name: 'Job' },
            ]
        }
    },
    {
        path: '/job/:id?',
        name: 'ViewJob',
        component: () => import('@/views/User/Jobs/ViewJob.vue'),
        meta: {
            title: "View Job",
            requiresAuth: true,
            requiresAdmin: false,
            breadcrumb: [
                { name: 'Jobs', link: '/jobs' },
                { name: 'Manage', link: '/jobs/list' },
                { name: 'View Job' },
            ]
        },
    },
    {
        path: '/posts',
        name: 'ViewPosts',
        component: () => import('@/views/User/ActivityFeed.vue'),
        meta: {
            title: "View Posts",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: '/applications',
        name: 'ViewApplications',
        component: () => import('@/views/User/VolunteerApplication.vue'),
        meta: {
            title: "View Applications",
            requiresAuth: true,
            requiresAdmin: false,
        },
    },
    {
        path: "/login",
        name: "Login",
        component: () => import('@/views/LoginView.vue'),
        meta: {
            title: "Login",
            requiresAuth: false,
        }
    },
    {
        path: "/register",
        name: "Register",
        component: () => import('@/views/RegisterView.vue'),
        meta: {
            title: "Register",
            requiresAuth: false,
        }
    },
    {
        path: "/reset",
        name: "Reset",
        component: () => import('@/views/ResetPassword.vue'),
        meta: {
            title: "Reset",
            requiresAuth: false,
        }
    },
]
