import axios from 'axios';
import store from '../store';
import Swal from 'sweetalert2';

const setApiAuthenticationHeader = (accessToken = null) => {
    if (accessToken) {
        axiosInstance.defaults.headers.common.authorization = `Bearer ${accessToken}`;
    } else {
        delete axiosInstance.defaults.headers.common.authorization;
    }
};

// const API_SERVER = "http://127.0.0.1:8000/api/v1"
const API_SERVER = "https://riphah.lablnet.com/api/v1"


// basic configurations
axios.defaults.baseURL = API_SERVER;
axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.common['Accept'] = 'application/json';

// create instance 
const axiosInstance = axios.create();

// Axios wrapper to handle error
const axiosWrapper = apiCall => apiCall.then(res => res.data).catch(err => Promise.reject(err));

// axios interceptors to handle token expiration
axiosInstance.interceptors.response.use(
    response => {
        return response;
    },
    error => {

        if (error.config
            && error.response
            && error.response.status === 402 || error.response.status === 500) {
                Swal.fire({
                    title: 'Oops...',
                    text: error.response.status === 402 ? 
                        "You do not have premission to perform this action" : "Something wrong in server",
                    type: 'error',
                    confirmButtonText: 'OK'
                });
                return Promise.reject(error);
            }
        if (error.config
            && error.response
            && error.response.status === 401) {
            const oldRefreshToken = store.getters['Auth/refreshToken'];
            if (oldRefreshToken) {
                return axios.post('/auth/refresh', { refreshToken: oldRefreshToken })
                .then(res => {
                    const {accessToken, refreshToken} = res.data;
                    if (accessToken && refreshToken) {
                        error.config.headers.authorization = `Bearer ${accessToken}`;
                        setApiAuthenticationHeader(accessToken);
                        localStorage.setItem('accessToken', accessToken);
                        localStorage.setItem('refreshToken', refreshToken);

                        // dispatch event that login successful and update the tokens.
                        // TODO.
                        return axiosInstance.request(error.config);
                    } else {
                        return Promise.reject("Can not get the access token");
                    }
                })
            }
        }
        return Promise.reject(error);
    }
);

// API calls.

let api = {
    auth: {
        register: (data) => axiosWrapper(axiosInstance.post('/auth/register', data)),
        login: (credentials) => axiosWrapper(axiosInstance.post('/auth/login', credentials)),
        logout: () => axiosWrapper(axiosInstance.post('/auth/logout')),
        me: () => axiosWrapper(axiosInstance.get('/auth/me')),
        reset: (email) => axiosWrapper(axiosInstance.post('/auth/password', {email: email})),
    },
    // Notificaion.
    notification: {
        index: (query) => axiosWrapper(axiosInstance.get(`/notifications?${query}`)),
        mark: (id) => axiosWrapper(axiosInstance.get(`/notifications/mark-read/${id}`)),
        markAll: () => axiosWrapper(axiosInstance.get(`/notifications/mark-all`)),
        delete: (id) => axiosWrapper(axiosInstance.delete(`/notifications/${id}`)),
        deleteAll: () => axiosWrapper(axiosInstance.delete(`/notifications`)),
    },
    admin: {
        dashboard: () => axiosWrapper(axiosInstance.get('/admin/dashboard')),
        permission: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/permissions?${query}`)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/permissions', data)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/permissions/${id}`)),
        },
        user: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/users?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/users/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/users/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/users/put/${id}`, data)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/users', data)),
            mark: (id, data) => axiosWrapper(axiosInstance.post(`/admin/users/mark/${id}`, data)),
            impersonate: (id) => axiosWrapper(axiosInstance.post(`/admin/users/impersonate/${id}`)),
        },

        alumni: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/alumni-database?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/alumni-database/${id}`)),
            import: (data) => axiosWrapper(axiosInstance.post('/admin/alumni-database/import', data)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/alumni-database/${id}`)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/alumni-database', data)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/alumni-database/put/${id}`, data)),
        },

        faculty: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/faculty?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/faculty/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/faculty/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/faculty/put/${id}`, data)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/faculty', data)),
            importFaculty: (data) => axiosWrapper(axiosInstance.post('/admin/faculty/import', data)),
        },

        degreeprograms: {
            index: (faculty_id, query) => axiosWrapper(axiosInstance.get(`/admin/degree-programs/${faculty_id}?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/degree-programs/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/degree-programs/${id}/show`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/degree-programs/put/${id}`, data)),
            post: (faculty_id, data) => axiosWrapper(axiosInstance.post(`/admin/degree-programs/${faculty_id}`, data)),
            importDegreeProgram: (data) => axiosWrapper(axiosInstance.post('/admin/degree-programs/import', data)),
        },

        jobs: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/jobs?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/jobs/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/jobs/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/jobs/put/${id}`, data)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/jobs', data)),
        },

        events: {
            index: (query) => axiosWrapper(axiosInstance.get(`/admin/events?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/events/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/events/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/events/put/${id}`, data)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/events', data)),
            addGallery: (id, data) => axiosWrapper(axiosInstance.post(`/admin/events/${id}/gallery`, data)),
            deleteGallery: (event_id, id) => axiosWrapper(axiosInstance.delete(`/admin/events/${event_id}/gallery/${id}`)),
        },

        // Volunteering routes.
        volunteering: {
            index: (id) => axiosWrapper(axiosInstance.get(`/admin/volunteering/${id}`)),
            postCall: (id) => axiosWrapper(axiosInstance.post(`/admin/volunteering/${id}/call`)),
            getCall: (id) => axiosWrapper(axiosInstance.get(`/admin/volunteering/${id}/call`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/admin/volunteering/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/admin/volunteering/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/admin/volunteering/put/${id}`, data)),
            post: (data) => axiosWrapper(axiosInstance.post('/admin/volunteering', data)),
            
            // Volunteering applications.
            applications: {
                index: (volunteering_id, query) => axiosWrapper(axiosInstance.get(`/admin/volunteering/${volunteering_id}/applications?${query}`)),
                status: (volunteering_id, id, data) => axiosWrapper(axiosInstance.post(`/admin/volunteering/${volunteering_id}/applications/${id}/status`, data)),
            }
        },
    },
    profile: {
        get: (id) => axiosWrapper(axiosInstance.get(`/profile/${id}`)),
    },
    alumni: {
        alumni: (query) => axiosWrapper(axiosInstance.get(`/alumni/alumni?${query}`)),
        jobs: (query) => axiosWrapper(axiosInstance.get(`/alumni/jobs?${query}`)),
        events: (query) => axiosWrapper(axiosInstance.get(`/alumni/events?${query}`)),
        event: (id) => axiosWrapper(axiosInstance.get(`/alumni/event/${id}`)),
        update: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/update/${id}`, data)),
        updateMeta: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/update-meta/${id}`, data)),
        deleteMeta: (id, key) => axiosWrapper(axiosInstance.delete(`/alumni/delete-meta/${id}?key=${key}`)),
        updatePassword: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/update-password/${id}`, data)),
        volunteers: (query) => axiosWrapper(axiosInstance.get(`/alumni/volunteers?${query}`)),
        volunteerEvent: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/volunteer-event/${id}`, data)),
        education: {
            index: () => axiosWrapper(axiosInstance.get(`/alumni/education`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/alumni/education/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/alumni/education/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/education/put/${id}`, data)),
        },
        experience: {
            index: () => axiosWrapper(axiosInstance.get(`/alumni/experience`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/alumni/experience/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/alumni/experience/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/experience/put/${id}`, data)),
        },
        posts: {
            index: (query) => axiosWrapper(axiosInstance.get(`/alumni/post?${query}`)),
            delete: (id) => axiosWrapper(axiosInstance.delete(`/alumni/post/${id}`)),
            get: (id) => axiosWrapper(axiosInstance.get(`/alumni/post/${id}`)),
            put: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/post/put/${id}`, data)),
            like: (id) => axiosWrapper(axiosInstance.post(`/alumni/post/${id}/like`)),
            postComment: (id, data) => axiosWrapper(axiosInstance.post(`/alumni/post/${id}/comment`, data)),
            deleteComment: (id, comment_id) => axiosWrapper(axiosInstance.delete(`/alumni/post/${id}/comment/${comment_id}`)),
            share: (id) => axiosWrapper(axiosInstance.post(`/alumni/post/${id}/share`)),
        },
        linkedin: {
            get: () => axiosWrapper(axiosInstance.get(`/alumni/linkedin`)),
            callback: (code, state) => axiosWrapper(axiosInstance.get(`/alumni/linkedin/callback?code=${code}&state=${state}`)),
        },
    },
    faculties: () => axiosWrapper(axiosInstance.get('/faculties')),
    degreePrograms: (faculty_id) => axiosWrapper(axiosInstance.get(`/departments/${faculty_id}`)),
    
};

export { api, axiosInstance, setApiAuthenticationHeader };

export default api;
