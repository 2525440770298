import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        alumni: [],
        jobs: [],
        job: {},
        events: [],
        event: {},
        error: null,
        volunteers: [],
    },
    mutations: {
        setAlumni(state: any, alumni: any) {
            state.alumni = alumni
        },
        setJobs(state: any, jobs: any) {
            state.jobs = jobs
        },
        setJob(state: any, job: any) {
            state.job = job
        },
        setEvents(state: any, events: any) {
            state.events = events
        },
        setEvent(state: any, event: any){
            state.event = event
        },
        setVolunteers(state: any, volunteers: any) {
            state.volunteers = volunteers
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
    },
    getters: {
        alumni(state: any) {
            return state.alumni
        },
        jobs(state: any) {
            return state.jobs
        },
        job(state: any) {
            return state.job
        },
        events(state: any) {
            return state.events
        },
        event(state: any) {
            return state.event
        },
        volunteers(state: any) {
            return state.volunteers
        },
        error(state: any) {
            return state.error
        },
    },
    actions: {
        getAlumni(context: any, query: any) {
            context.commit('setError', null);
            return api.alumni.alumni(query).then((response: any) => {
                context.commit('setAlumni', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getJobs(context: any, query: any) {
            context.commit('setError', null);
            return api.alumni.jobs(query).then((response: any) => {
                context.commit('setJobs', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEvents(context: any, query: any) {
            context.commit('setError', null);
            return api.alumni.events(query).then((response: any) => {
                context.commit('setEvents', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEvent(context: any, id: any) {
            context.commit('setError', null);
            return api.alumni.event(id).then((response: any) => {
                context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateUser(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.update(data.id, data.form).then((response: any) => {
                context.commit('Auth/setUser', JSON.stringify(response), {root:true});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateUserMeta(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.updateMeta(data.id, data.form).then((response: any) => {
                context.commit('Auth/setMeta', JSON.stringify(response), {root:true});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteUserMeta(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.deleteMeta(data.id, data.key).then((response: any) => {
                context.commit('Auth/setMeta', JSON.stringify(response), {root:true});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updatePassword(context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.updatePassword(data.id, data.form).then((response: any) => {
                //context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getVolunteers(context: any, query: any) {
            context.commit('setError', null);
            return api.alumni.volunteers(query).then((response: any) => {
                context.commit('setVolunteers', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        volunteerEvent (context: any, data: any) {
            context.commit('setError', null);
            return api.alumni.volunteerEvent(data.event_id, data.data).then((response: any) => {
                //return "Success";
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
    },
}
