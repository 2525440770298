export default [
    {
        path: 'dashboard',
        name: 'adminDashboard',
        component: () => import('@/views/Admin/DashboardView.vue'),
        meta: {
            title: "Dashboard",
            breadcrumb: [
            { name: 'Dashboard'},
            ]
        }
    },
]
