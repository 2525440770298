import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        jobs: [],
        job: {},
        id: null,
        error: null,
    },
    mutations: {
        setJobs(state: any, jobs: any) {
            state.jobs = jobs;
        },
        setJob(state: any, job: any) {
            state.job = job;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        jobs(state: any) {
            return state.jobs;
        },
        job(state: any) {
            return state.job;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        postJob(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.jobs.post(data).then((response: any) => {
                context.commit('setJob', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateJob(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.jobs.put(data.id, data.form).then((response: any) => {
                context.commit('setJob', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getJob(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.jobs.get(id).then((response: any) => {
                context.commit('setJob', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getJobs(context: any, page: number) {
            context.commit('setError', null);
            return api.admin.jobs.index(page).then((response: any) => {
                context.commit('setJobs', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteJob(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.jobs.delete(id).then((response: any) => {
                context.commit('setJob', {});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
