import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        events: [],
        event: {},
        id: null,
        error: null,
    },
    mutations: {
        setEvents(state: any, events: any) {
            state.events = events
        },
        setEvent(state: any, event: any) {
            state.event = event
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        events(state: any) {
            return state.events
        },
        event(state: any) {
            return state.event
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        postEvent(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.events.post(data).then((response: any) => {
                context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateEvent(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.events.put(data.id, data.form).then((response: any) => {
                context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEvent(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.events.get(id).then((response: any) => {
                context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getEvents(context: any, page: number) {
            context.commit('setError', null);
            return api.admin.events.index(page).then((response: any) => {
                context.commit('setEvents', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteEvent(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.events.delete(id).then((response: any) => {
                context.commit('setEvent', {});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        addGallery(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.events.addGallery(data.id, data.images).then((response: any) => {
                //context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteGallery(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.events.deleteGallery(data.event_id, data.id).then((response: any) => {
                //context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
    },
}
