export default [
    {
        path: '/',
        name: 'home',
        component: () => import('@/views/HomeView.vue'),
    },
    {
        path: "/about",
        name: "about",
        component: () => import('@/views/AboutView.vue'),
        meta: {
            title: "About",
        }
    },
    {
        path: "/contact",
        name: "Contact",
        component: () => import('@/views/ContactView.vue'),
        meta: {
            title: "Contact",
        }
    },
]
