export default [
    {
        path: 'permission/:id',
        name: 'viewPermission',
        component: () => import('@/views/Admin/Users/PermissionsView.vue'),
        meta: {
            title: "View Permission",
            requiresPermission: 'admin.users.permissions',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard' },
            { name: 'Users', link: '/admin/users' },
            { name: 'View Permission'},
            ]
        }
    },
    {
        path: 'users',
        name: 'adminUsers',
        component: () => import('@/views/Admin/Users/UsersView.vue'),
        meta: {
            title: "Users",
            requiresPermission: 'admin.users.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Users'},
            ]
        }
    },
    {
        path: 'user/:id?',
        name: 'viewUser',
        component: () => import('@/views/Admin/Users/UserView.vue'),
        meta: {
            title: "View User",
            requiresPermission: 'admin.users.show',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Users', link: '/admin/users'},
            { name: 'View User'},
            ]
        }
    },
    {
        path: 'user/manage/:id?',
        name: 'manageUser',
        component: () => import('@/views/Admin/Users/ManageUser.vue'),
        meta: {
            title: "Add User",
            requiresPermission: 'admin.users.create',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Users', link: '/admin/users'},
            { name: 'Add User'},
            ]
        }
    },
    {
        path: 'alumni',
        name: 'adminAlumni',
        component: () => import('@/views/Admin/Alumni/AlumniView.vue'),
        meta: {
            title: "Alumni",
            requiresPermission: 'admin.users.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Users', link: '/admin/users'},
            { name: 'Alumni'},
            ]
        }
    },
    {
        path: 'alumni/import',
        name: 'adminAlumniImport',
        component: () => import('@/views/Admin/Alumni/ImportView.vue'),
        meta: {
            title: "Alumni Import",
            requiresPermission: 'admin.users.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Users', link: '/admin/users'},
            { name: 'Alumni', link: '/admin/alumni'},
            { name: 'Alumni Import'},
            ]
        }
    },
    {
        path: 'alumni/manage/:id?',
        name: 'manageAlumni',
        component: () => import('@/views/Admin/Alumni/manageAlumni.vue'),
        meta: {
            title: "Add Alumi",
            requiresPermission: 'admin.users.create',
            breadcrumb: [
                { name: 'Dashboard', link: '/admin/dashboard'},
                { name: 'Users', link: '/admin/users'},
                //{ name: 'Alumni', link: '/admin/alumni'},
                { name: 'Add Alumni'},
            ]
        }
    },
]
