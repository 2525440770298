<template>
  <div>
    <ImpersonateScreen />
    <div class="dark:text-white light-text-color bg-white dark:bg-gray-700">
      <header
        x-data="{ mobileMenuOpen : false }"
        class="
          flex flex-row
          justify-between
          md:items-center md:space-x-4
          py-6
          px-6
          top-0
          z-50
          animated
          shadow-lg
          dark:bg-gray-900
          bg-white
          relative
        "
        v-if="!isAdmin"
        ref="nav"
      >
        <router-link
          class="block p-2 hover:text-blue-500 rounded-lg font-bold"
          :to="{ path: '/' }"
        >
          <span class="sr-only"></span>
          Riphah Alumni
        </router-link>
        <span class="inline-block mx-auto mr-1 mt-1 md:hidden">
          <ThemeChanger :theme="appTheme"
        /></span>

        <button
          @click="mobileMenuOpen = !mobileMenuOpen"
          class="inline-block md:hidden w-8 h-8 p-1"
        >
          <svg
            fill="currentColor"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
              clip-rule="evenodd"
            ></path>
          </svg>
        </button>

        <nav
          class="
            absolute
            md:relative
            top-16
            left-0
            md:top-0
            z-20
            md:flex
            flex-col
            md:flex-row md:space-x-6
            font-semibold
            w-full
            md:w-auto
            shadow-md
            rounded-lg
            md:rounded-none md:shadow-none
            p-6
            pt-0
            md:p-0
            dark:bg-gray-900
            bg-white
          "
          :class="{ flex: mobileMenuOpen, hidden: !mobileMenuOpen }"
          click="mobileMenuOpen = false"
        >
          <router-link
            v-if="isLogin"
            to="/alumni"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-graduation-cap" aria-hidden="true"></i>
            Alumni</router-link
          >
          <router-link
            v-if="isLogin"
            to="/jobs"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-tasks"></i> Jobs</router-link
          >
          <router-link
            v-if="isLogin"
            to="/events"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-calendar"></i> Events</router-link
          >
          <router-link
            to="/about"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-info-circle"></i> About</router-link
          >
          <router-link
            to="/contact"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-address-book"></i> Contact</router-link
          >
          <router-link
            v-if="!isLogin"
            to="/register"
            class="block p-2 hover:text-blue-500 rounded-lg"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-user"></i> Register</router-link
          >
          <router-link
            v-if="!isLogin"
            to="/login"
            class="block py-2 hover:text-blue-500"
            @click="mobileMenuOpen = false"
            ><i class="fa-solid fa-sign-in"></i> Login</router-link
          >
          <span
            v-if="isLogin"
            class="flex p-2 hover:text-blue-500 rounded-lg cursor-pointer"
            @click="notificationHandler"
            ><i class="fa fa-bell"></i>
            <span class="text-sm -mt-4 text-red-500">{{
              notifications?.total
            }}</span></span
          >

          <ul class="flex items-center flex-shrink-0 space-x-6" v-if="isLogin">
            <li class="relative">
              <button
                class="
                  align-middle
                  rounded-full
                  focus:shadow-outline-purple focus:outline-none
                "
                aria-label="Account"
                aria-haspopup="true"
                @click="isProfileMenuOpen = !isProfileMenuOpen"
                ref="profileMenu"
              >
                <span class="flex">
                  <img
                    class="object-cover w-8 h-8 rounded-full"
                    :src="user.profile_picture"
                    :alt="user.first_name"
                    aria-hidden="true"
                    ref="profileMenuPic"
                  />
                  <span
                    class="mt-1 px-1 text-black text-sm mx-2"
                    ref="profileMenuName"
                    >{{ user.name }}
                  </span>
                </span>
              </button>
              <template v-if="isProfileMenuOpen">
                <ul
                  class="
                    absolute
                    right-0
                    w-56
                    p-2
                    mt-2
                    space-y-2
                    text-gray-600
                    bg-white
                    border border-gray-100
                    rounded-md
                    shadow-md
                    dark:border-gray-700 dark:text-gray-300 dark:bg-gray-700
                    z-40
                  "
                  aria-label="submenu"
                >
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/admin/dashboard"
                      v-if="
                        user.user_role == 'superadmin' ||
                        user.user_role == 'admin'
                      "
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-user mx-2"></i>
                      <span>Admistrator</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/profile"
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-user mx-2"></i>
                      <span>Profile</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/posts"
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-rss mx-2"></i>
                      <span>Activity Feed</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/applications"
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-list mx-2"></i>
                      <span>Volunteering Applications</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/settings"
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-cogs mx-2"></i>
                      <span>Settings</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <router-link
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      to="/change-password"
                      @click="isProfileMenuOpen = false"
                    >
                      <i class="fa fa-shield mx-2"></i>
                      <span>Change password</span>
                    </router-link>
                  </li>
                  <li class="flex">
                    <a
                      class="
                        inline-flex
                        items-center
                        w-full
                        px-2
                        py-1
                        text-sm
                        font-semibold
                        transition-colors
                        duration-150
                        rounded-md
                        hover:bg-gray-100 hover:text-gray-800
                        dark:hover:bg-gray-800 dark:hover:text-gray-200
                      "
                      href="#!"
                      @click.prevent="logout"
                    >
                      <i class="fa fa-sign-out mx-2"></i>
                      <span>Log out</span>
                    </a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </nav>
      </header>
    </div>
    <NotificationComp />
    <router-view />

    <footer class="text-gray-600 body-font">
      <div class="bg-gray-100 dark:text-white dark:bg-gray-900">
        <div
          class="
            container
            px-5
            py-6
            mx-auto
            flex
            items-center
            justify-center
            sm:flex-row
            flex-col
          "
        >
          <router-link
            to="/"
            class="
              flex
              title-font
              font-medium
              items-center
              md:justify-start
              justify-center
            "
          >
          </router-link>
          <p
            class="
              inline-flex
              text-sm text-gray-500
              dark:text-gray-300
              sm:ml-6 sm:mt-0
              mt-4
            "
          >
            Made with &nbsp;
            <img class="h-6 w-6" :src="require('./assets/images/heart.gif')" />
            &nbsp; By &nbsp;
            <a class="font-bold" href="https://www.lablnet.com" target="_blank"
              >Muhammad Umer Farooq</a
            >
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="js">
//import Banner from "@/components/Banner";
import ThemeChanger from "@/components/ThemeChanger";
import ImpersonateScreen from "@/components/Screens/ImpersonateScreen";
import NotificationComp from "@/components/NotificationComp";

// fancybox.
import {
    Fancybox
} from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox.css";

export default {
    name: 'app',
    components: {
        ImpersonateScreen,
        NotificationComp,
        //ThemeChanger,
        // Banner
    },
    watch: {
        $route(to, from) {
            const base = `Riphah`
            let title = ""
            if (to.path !== "/") this.path = '/'
            else this.path = ""

            if (typeof to.meta.title === 'string')
                title = `${to.meta.title} - ${base}`
            else if (typeof to.meta.title === 'function')
                title = `${to.meta.title(to)} - ${base}`
            else title = base
            document.title = title

            // check if route contain admin.
            if (to.path.indexOf('admin') > -1) {
                this.isAdmin = true
            } else {
                this.isAdmin = false
            }

            // if route comes from login.
            if (from.path.indexOf('login') > -1) {
                this.user = this.$store.getters['Auth/user']
            }
        },
    },
    data() {
        return {
            top: false,
            appTheme: localStorage.getItem('theme') || 'light',
            path: "",
            scroll: 0,
            isProfileMenuOpen: false,
            user: {},
            isAdmin: false,
            mobileMenuOpen: false,
            showNotification: false,
            notifications: 0,
        }
    },
    created() {
        // Fancybox init.
        let fancyboxELems = ["[data-fancybox='default']"]
        // for in loop.
        for (let i = 0; i < fancyboxELems.length; i++) {
            Fancybox.bind(fancyboxELems[i], {
                caption: function (fancybox, carousel, slide) {
                    return (
                        slide.caption || ""
                    );
                },
            });
        }

        // back to top button handler.
        window.addEventListener('scroll', this.handleScroll);

        // subscribe to theme changes.
        this.unsubscribe = this.$store.subscribe((mutation, state) => {
            if (mutation.type === 'Theme/setTheme') {
                this.appTheme = mutation.payload
            }
        })

        // if user is logged in, set the api heade token.
        if (this.$store.getters['Auth/login']) {
            this.$store.dispatch('Auth/setToken')
            this.$store.dispatch('Auth/getMe')

            window.setInterval(() => {
                this.$store.dispatch('Notification/getNotifications', "?page=1").then((response) => {
                    this.notifications = this.$store.getters['Notification/notifications'];
                })
            }, 10000)
        }
    },
    computed: {
        theme() {
            return this.$store.getters['Theme/theme']
        },
        isLogin() {
            return this.$store.getters['Auth/login']
        },
    },
    mounted() {
        window.addEventListener("click", (e) => {
            // if the target of the click isn't the container nor a descendant of the container.
            if (this.$refs.profileMenu !== e.target &&
                this.$refs.profileMenuName !== e.target &&
                this.$refs.profileMenuPic !== e.target
            ) {
                this.isProfileMenuOpen = false
            }
        })

        this.user = this.$store.getters['Auth/user']
        this.emitter.on('closeNotificationPanel', this.closeNotificationPanel)
        this.emitter.on('profile-updated', this.profileUpdated)
    },
    methods: {
        profileUpdated() {
            // refresh.
            this.$store.dispatch('Auth/getMe').then(() => {
                this.user = this.$store.getters['Auth/user']
            })
        },
        closeNotificationPanel() {
            this.showNotification = false
        },
        notificationHandler() {
            this.showNotification = !this.showNotification
            this.emitter.emit('notificationHandler', this.showNotification)
        },
        scrollTop() {
            window.scrollTo(0, 0);
        },
        handleScroll(event) {
            let scroll = document.body.scrollTop || document.documentElement.scrollTop;
            if (scroll > 150) {
                this.top = true
            } else {
                this.top = false
                //this.manageNavScroll('remove')
            }
            this.scroll = scroll;
        },
        logout() {
            this.isProfileMenuOpen = false;
            this.$store.dispatch('Auth/logout').then(() => {
                localStorage.removeItem('accessToken');
                localStorage.removeItem('refreshToken');
                localStorage.removeItem('user');
                localStorage.removeItem('meta');
                localStorage.removeItem('permissions');

                this.$router.push('/login')
            })
        },
    },
    beoreDestroy() {
        this.unsubscribe()
    },
}
</script>
