export default [
    {
        path: 'faculties',
        name: 'adminFaculty',
        component: () => import('@/views/Admin/Faculty/FacultiesView.vue'),
        meta: {
            title: "Faculties",
            requiresPermission: 'admin.faculty.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties'},
            ]
        }
    },
    {
        path: 'faculty/manage/:id?',
        name: 'manageFaculty',
        component: () => import('@/views/Admin/Faculty/ManageFaculty.vue'),
        meta: {
            title: "Manage Faculty",
            requiresPermission: 'admin.faculty.create',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties', link: '/admin/faculties'},
            { name: 'Add Faculty'},
            ]
        }
    },
    {
        path: 'faculty/import',
        name: 'importFaculty',
        component: () => import('@/views/Admin/Faculty/ImportFaculty.vue'),
        meta: {
            title: "Import Faculty",
            //requiresPermission: 'admin.faculty.import',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties', link: '/admin/faculties'},
            { name: 'Import Faculty'},
            ]
        }
    },
]
