export default [
    {
        path: ':faculty_id/degree-programs',
        name: 'adminDegreeProgram',
        component: () => import('@/views/Admin/DegreePrograms/DegreePrograms.vue'),
        meta: {
            title: "DegreeProgram",
            requiresPermission: 'admin.degree.programs.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties', link: '/admin/faculties'},
            { name: 'Degree Programs'},
            ]
        }
    },
    {
        path: ':faculty_id/degree-program/manage/:id?',
        name: 'manageDegreeProgram',
        component: () => import('@/views/Admin/DegreePrograms/ManageDegreeProgram.vue'),
        meta: {
            title: "Manage Degree Program",
            requiresPermission: 'admin.degree.programs.create',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties', link: '/admin/faculties'},
            { name: 'Degree Programs', link: '/admin/:id/degree-programs'},
            { name: 'Add Degree Program'},
            ]
        }
    },
    {
        path: ':faculty_id/degree-program/import',
        name: 'importDegreeProgram',
        component: () => import('@/views/Admin/DegreePrograms/ImportDegreeProgram.vue'),
        meta: {
            title: "Import Degree Program",
            //requiresPermission: 'admin.degree.programs.import',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Faculties', link: '/admin/faculties'},
            { name: 'Degree Programs', link: '/admin/:id/degree-programs'},
            { name: 'Import Degree Program'},
            ]
        }
    },
]
