import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        users: [],
        user: {},
        meta: [],
        education: [],
        experience: [],
        id: null,
        error: null,
        permissions: [],
        faculties: [],
        departments: [],
    },
    mutations: {
        setUsers(state: any, users: any) {
            state.users = users;
        },
        setUser(state: any, user: any) {
            state.user = user;
        },
        setMeta(state: any, meta:any) {
            state.meta = meta;
        },
        setEducation(state: any, education:any) {
            state.education = education;
        },
        setExperience(state: any, experience:any) {
            state.experience = experience;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        },
        setPermissions(state: any, permissions: any) {
            state.permissions = permissions;
        },
        setFaculties(state: any, faculties: any) {
            state.faculties = faculties;
        },
        setDepartments(state: any, departments: any) {
            state.departments = departments;
        },
    },
    getters: {
        users(state: any) {
            return state.users;
        },
        user(state: any) {
            return state.user;
        },
        meta(state: any) {
            return state.meta;
        },
        education(state: any) {
            return state.education;
        },
        experience(state: any) {
            return state.experience;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
        permissions(state: any) {
            return state.permissions;
        },
        faculties(state: any) {
            return state.faculties;
        },
        departments(state: any) {
            return state.departments;
        },
    },
    actions: {
        markUser(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.user.mark(data.id, data).then((response: any) => {
                //context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        postUser(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.user.post(data).then((response: any) => {
                context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateUser(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.user.put(data.id, data.form).then((response: any) => {
                context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getUser(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.user.get(id).then((response: any) => {
                context.commit('setUser', response);
                context.commit('setMeta', response.meta);
                context.commit('setEducation', response.education);
                context.commit('setExperience', response.experience);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getUsers(context: any, page: number) {
            context.commit('setError', null);
            return api.admin.user.index(page).then((response: any) => {
                context.commit('setUsers', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteUser(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.user.delete(id).then((response: any) => {
                context.dispatch('getUsers', 1);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getPermissions(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.permission.index(data).then((response: any) => {
                context.commit('setPermissions', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        postPermission(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.permission.post(data).then((response: any) => {
                context.commit('setPermissions', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deletePermission(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.permission.delete(id).then((response: any) => {
                // Do nothing.
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getProfile(context: any, id: number) {
            context.commit('setError', null);
            return api.profile.get(id).then((response: any) => {
                context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getFaculties(context: any) {
            context.commit('setError', null);
            return api.faculties().then((response: any) => {
                context.commit('setFaculties', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getDepartments(context: any, facultyId: number) {
            context.commit('setError', null);
            return api.degreePrograms(facultyId).then((response: any) => {
                context.commit('setDepartments', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getAlumni(context: any, query: any) {
            context.commit('setError', null);
            return api.admin.alumni.index(query).then((response: any) => {
                context.commit('setUsers', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getOneAlumni(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.alumni.get(id).then((response: any) => {
                context.commit('setUser', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        postAlumni(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.alumni.post(data).then((response: any) => {
                context.dispatch('getAlumni', 1);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateAlumni(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.alumni.put(data.id, data.form).then((response: any) => {
                // Do Nothing
                // context.dispatch('getAlumni', 1);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteAlumni(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.alumni.delete(id).then((response: any) => {
                context.dispatch('getAlumni', 1);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        importAlumni(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.alumni.import(data).then((response: any) => {
                context.dispatch('getAlumni', 1);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
    },
}
