export default [
    {
        path: 'jobs',
        name: 'adminJobs',
        component: () => import('@/views/Admin/Jobs/JobsView.vue'),
        meta: {
            title: "Jobs",
            requiresPermission: 'admin.jobs.index',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Jobs'},
            ]
        }
    },
    {
        path: 'job/manage/:id?',
        name: 'manageJob',
        component: () => import('@/views/Admin/Jobs/ManageJob.vue'),
        meta: {
            title: "Manage Job",
            requiresPermission: 'admin.jobs.create',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Jobs', link: '/admin/jobs'},
            { name: 'Add Job'},
            ]
        }
    },
    {
        path: 'job/:id?',
        name: 'viewJob',
        component: () => import('@/views/Admin/Jobs/ViewJob.vue'),
        meta: {
            title: "View job",
            requiresPermission: 'admin.jobs.show',
            breadcrumb: [
            { name: 'Dashboard', link: '/admin/dashboard'},
            { name: 'Jobs', link: '/admin/jobs'},
            { name: 'View Job'},
            ]
        }
    },
]
