import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        faculties: [],
        faculty: {},
        id: null,
        error: null,
    },
    mutations: {
        setFaculties(state: any, faculties: any) {
            state.faculties = faculties;
        },
        setFaculty(state: any, faculty: any) {
            state.faculty = faculty;
        },
        setError(state: any, error: any) {
            state.error = getError(error);
        },
        setId(state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        faculties(state: any) {
            return state.faculties;
        },
        faculty(state: any) {
            return state.faculty;
        },
        error(state: any) {
            return state.error;
        },
        id(state: any) {
            return state.id;
        },
    },
    actions: {
        postFaculty(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.faculty.post(data).then((response: any) => {
                context.commit('setFaculty', response.data);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        updateFaculty(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.faculty.put(data.id, data.form).then((response: any) => {
                context.commit('setFaculty', response.data);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getFaculty(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.faculty.get(id).then((response: any) => {
                context.commit('setFaculty', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getFaculties(context: any, page: number) {
            context.commit('setError', null);
            return api.admin.faculty.index(page).then((response: any) => {
                context.commit('setFaculties', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteFaculty(context: any, id: number) {
            context.commit('setError', null);
            return api.admin.faculty.delete(id).then((response: any) => {
                context.commit('setFaculty', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        importFaculty(context: any, data: any) {
            context.commit('setError', null);
            return api.admin.faculty.importFaculty(data).then((response: any) => {
                //context.commit('setEvent', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
