import { createStore } from 'vuex'
import { api } from '../../services/api'
import { getError } from "@/utils"

export default {
    namespaced: true,
    state: {
        volunteers: [],
        call: null,
        id: null,
        error: null,
    },
    mutations: {
        setVolunteers (state: any, volunteers: any) {
            state.volunteers = volunteers
        },
        setCall (state: any, call: any) {
            // check if vollunteer is empty object
            state.call = call
        },
        setError (state: any, error: any) {
            state.error = getError(error);
        },
        setId (state: any, id: any) {
            state.id = id;
        }
    },
    getters: {
        volunteers (state: any) {
            return state.volunteers
        },
        call (state: any) {
            return state.call
        },
        error (state: any) {
            return state.error;
        },
        id (state: any) {
            return state.id;
        },
    },
    actions: {
        getVolunteerCall (context: any, id: number) {
            context.commit('setError', null);
            return api.admin.volunteering.getCall(id).then((response: any) => {
                context.commit('setCall', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        startVolunteerCall (context: any, id: number) {
            context.commit('setError', null);
            return api.admin.volunteering.postCall(id).then((response: any) => {
                context.commit('setCall', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        getVolunteers (context: any, id:number) {
            context.commit('setError', null);
            return api.admin.volunteering.index(id).then((response: any) => {
                context.commit('setVolunteers', response);
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        deleteVolunteer (context: any, id: number) {
            context.commit('setError', null);
            return api.admin.volunteering.delete(id).then((response: any) => {
                context.commit('setVolunteer', {});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        },
        approveDisapproveApplication(context: any, data: any) {
            return api.admin.volunteering.applications.status(
                data.call_id,
                data.id,
                {
                    status: data.status,
                    description: data.description,
                }
            ).then((response: any) => {
                //context.commit('setVolunteer', {});
            }).catch((error: any) => {
                context.commit('setError', error);
            });
        }
    },
}
